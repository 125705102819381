import {
  Component,
  PageHeaderV2,
  TabProperty,
  TabsV3,
  Tips,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, UserEntity, toLatestUserPath } from '@atomica.co/irori';
import { Count, UserId } from '@atomica.co/types';
import { EMPTY, embedIdInPath, hasLength, toTimeStr, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { getBsUser } from '../../__generated/admin/bs-user/bs-user';
import { FetchTodayUsersForAdmin200 } from '../../__generated/model';
import BasePolaroid from '../../components/cards/BasePolaroid';
import { ACCESS_DIRECTION_COLORS } from '../../constants/shot-const';
import { VISITOR_TAB_LABELS, VisitorTab } from '../../constants/user-v2-const';
import { database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { ACCESS_DIRECTION_LABELS } from '../../texts/access-text';
import { toFullName } from '../../utils/user-util';

interface P {
  base: BaseDto;
}

const toUserIds = (users: { userId: UserId; createdAt?: Date; startAt?: Date }[]): UserId[] => {
  return users.map(user => [user.userId, user?.startAt ?? user?.createdAt ?? uuid()].join('-'));
};

const toBadge = (userIds: UserId[], watchedUserIds: UserId[]): Count | undefined => {
  return userIds.filter(userId => !watchedUserIds.includes(userId)).length || undefined;
};

const VisitorsScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { openPath } = usePath();

  const unmountRef = useUnmountRef();
  const [shotUsers, setShotUsers] = useSafeState<UserEntity[]>(unmountRef, []);
  const [members, setMembers] = useSafeState<UserEntity[]>(unmountRef, []);
  const [dropInUsers, setDropInUsers] = useSafeState<FetchTodayUsersForAdmin200['dropInUsers']>(unmountRef, []);

  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<VisitorTab>(unmountRef, VisitorTab.SHOT_USERS);

  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);

  const [watchedShotUserIds, setWatchedShotUserIds] = useSafeState<UserId[]>(unmountRef, []);
  const [watchedMemberIds, setWatchedMemberIds] = useSafeState<UserId[]>(unmountRef, []);
  const [watchedDropInUserIds, setWatchedDropInUserIds] = useSafeState<UserId[]>(unmountRef, []);

  const shotUserIds = useMemo<UserId[]>(() => toUserIds(shotUsers), [shotUsers]);
  const memberIds = useMemo<UserId[]>(() => toUserIds(members), [members]);
  const dropInUserIds = useMemo<UserId[]>(() => toUserIds(dropInUsers), [dropInUsers]);

  const tabs = useMemo<TabProperty[]>(() => {
    const [shotUserLabel, memberLabel, dropInUserLabel] = Object.values(VISITOR_TAB_LABELS);
    const userTabMapping = {
      [shotUserLabel]: toBadge(shotUserIds, watchedShotUserIds),
      [memberLabel]: toBadge(memberIds, watchedMemberIds)
    };
    const baseTabs = Object.entries(userTabMapping).map(([label, badge]) => ({ label, badge }));
    return hasLength(dropInUsers)
      ? baseTabs.concat({ label: dropInUserLabel, badge: toBadge(dropInUserIds, watchedDropInUserIds) })
      : baseTabs;
  }, [dropInUserIds, dropInUsers, memberIds, shotUserIds, watchedDropInUserIds, watchedMemberIds, watchedShotUserIds]);

  const handleTabSelected = useSafeCallback(
    (selectedTabIdx: VisitorTab): void => {
      setSelectedTabIdx(selectedTabIdx);
      switch (selectedTabIdx) {
        case VisitorTab.SHOT_USERS:
          setWatchedShotUserIds(toUserIds(shotUsers));
          break;
        case VisitorTab.MEMBERS:
          setWatchedMemberIds(toUserIds(members));
          break;
        case VisitorTab.DROP_IN_USERS:
          setWatchedDropInUserIds(toUserIds(dropInUsers));
          break;
        default:
          selectedTabIdx satisfies never;
      }
    },
    [
      dropInUsers,
      members,
      setSelectedTabIdx,
      setWatchedDropInUserIds,
      setWatchedMemberIds,
      setWatchedShotUserIds,
      shotUsers
    ]
  );

  const loadTodayUsers = useSafeCallback(async (): Promise<void> => {
    const { data } = await getBsUser().fetchTodayUsersForAdmin(base.baseId);
    setShotUsers(data.shotUsers);
    setMembers(data.members);
    setDropInUsers(data.dropInUsers);
    setIsInitialized(true);
  }, [base.baseId, setDropInUsers, setIsInitialized, setMembers, setShotUsers]);

  useEffect(() => {
    const ref = database.ref(toLatestUserPath(base.baseCode));
    ref.on('value', () => loadTodayUsers());
  }, [base, loadTodayUsers]);

  const handleCardClicked = useSafeCallback(
    (userId: UserId): void => {
      openPath(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [base.baseCode, userId]));
    },
    [base, openPath]
  );

  const polaroids = useMemo<JSX.Element[]>(() => {
    switch (selectedTabIdx) {
      case VisitorTab.SHOT_USERS:
        return shotUsers.map((user, index) => (
          <BasePolaroid
            key={'shot-polaroid-' + index}
            userName={toFullName(user)}
            photoUrl={user.photoURL}
            tagName={user.itemName}
            onClick={() => handleCardClicked(user.userId)}
          >
            <PolaroidRow>
              <PolaroidText>所属</PolaroidText>
              <PolaroidText>{user.receiptName}</PolaroidText>
            </PolaroidRow>
            <PolaroidRow>
              <PolaroidText>入館時刻</PolaroidText>
              <PolaroidText>{user.createdAt ? toTimeStr(user.createdAt) : EMPTY}</PolaroidText>
            </PolaroidRow>
            <PolaroidRow>
              <PolaroidText>貸出No.</PolaroidText>
              <PolaroidText>{user.cardNo}</PolaroidText>
            </PolaroidRow>
            <PolaroidRow>
              <PolaroidText>担当者</PolaroidText>
              <PolaroidText>{user.personInCharge}</PolaroidText>
            </PolaroidRow>
            <PolaroidRow>
              <PolaroidText>備考</PolaroidText>
              <PolaroidText>{user.remarks}</PolaroidText>
            </PolaroidRow>
          </BasePolaroid>
        ));
      case VisitorTab.MEMBERS:
        return members.map((user, index) => (
          <BasePolaroid
            key={'member-polaroid-' + index}
            userName={toFullName(user)}
            photoUrl={user.photoURL}
            tagName={user.direction ? ACCESS_DIRECTION_LABELS[user.direction] : undefined}
            tagColor={user.direction ? ACCESS_DIRECTION_COLORS[user.direction] : undefined}
            onClick={() => handleCardClicked(user.userId)}
          >
            <PolaroidRow>
              <PolaroidText>入退館時刻</PolaroidText>
              <PolaroidText>{user.createdAt ? toTimeStr(user.createdAt) : EMPTY}</PolaroidText>
            </PolaroidRow>
          </BasePolaroid>
        ));
      case VisitorTab.DROP_IN_USERS:
        return dropInUsers.map((user, index) => (
          <BasePolaroid
            key={'drop-in-polaroid-' + index}
            userName={user.userName}
            photoUrl={user.photoUrl}
            tagName={user.checkoutAt ? '利用終了済み' : undefined}
            onClick={() => handleCardClicked(user.userId)}
          >
            <PolaroidRow>
              <PolaroidText>所属</PolaroidText>
              <PolaroidText>{user.receiptName}</PolaroidText>
            </PolaroidRow>
            <PolaroidRow>
              <PolaroidText>入館時刻</PolaroidText>
              <PolaroidText>{toTimeStr(user.startAt)}</PolaroidText>
            </PolaroidRow>
            <PolaroidRow>
              <PolaroidText>退館予定時刻</PolaroidText>
              <PolaroidText>{toTimeStr(user.endAt)}</PolaroidText>
            </PolaroidRow>
            <PolaroidRow>
              <PolaroidText>プラン名</PolaroidText>
              <PolaroidText>{user.planName}</PolaroidText>
            </PolaroidRow>
          </BasePolaroid>
        ));
    }
  }, [dropInUsers, handleCardClicked, members, selectedTabIdx, shotUsers]);

  return (
    <Component className='visitors-screen'>
      <Container data-testid='visitors-screen'>
        <PageHeaderV2 title='本日の利用者' />
        <TabsV3 tabs={tabs} selectedTabIdx={selectedTabIdx} onChange={handleTabSelected} />
        {isInitialized && hasLength(polaroids) && <PolaroidsWrapper>{polaroids}</PolaroidsWrapper>}
        {isInitialized && !hasLength(polaroids) && (
          <TipsWrapper>
            <Tips message='本日の利用者はいません。' />
          </TipsWrapper>
        )}
      </Container>
    </Component>
  );
});

VisitorsScreen.displayName = 'VisitorsScreen';
export default VisitorsScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 3}px;
`;

const PolaroidsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  gap: ${themeV3.mixins.v3.spacing * 2}px;
`;

const PolaroidRow = styled.div`
  width: 100%;
  display: flex;
  gap: ${themeV3.mixins.v3.spacing / 2}px;
`;

const PolaroidText = styled.span`
  ${themeV3.mixins.v3.typography.body.medium};
  color: ${themeV3.mixins.v3.color.object.black};
  min-width: 100px;
`;

const TipsWrapper = styled.div`
  margin-top: 48px;
`;
