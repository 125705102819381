import {
  BackButtonV2,
  ButtonV2,
  PageHeaderV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  ContractV2,
  FETCH_SPACE_RESERVATION_FOR_ADMIN,
  FetchSpaceReservationForAdminRequest,
  FetchSpaceReservationForAdminResponse,
  SPACE_RESERVATION_ID,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationPaymentStatus,
  SpaceReservationStatus,
  UPDATE_RESOURCE_RESERVATION_PAYMENT_STATUS_FOR_ADMIN,
  UPDATE_SPACE_RESERVATION_STATUS_FOR_ADMIN,
  UpdateResourceReservationPaymentStatusForAdminRequest,
  UpdateResourceReservationPaymentStatusForAdminResponse,
  UpdateSpaceReservationStatusForAdminRequest,
  UpdateSpaceReservationStatusForAdminResponse,
  User
} from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Card from '../../components/cards/Card';
import DefaultModal from '../../components/modal/DefaultModal';
import Screen from '../../components/screen/Screen';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import ChangeSpaceModal from './modal/ChangeSpaceModal';
import ReservationDetailBasic from './reservation-detail/ReservationDetailBasic';
import ReservationDetailOther from './reservation-detail/ReservationDetailOther';
import ReservationStatement from './reservation-detail/ReservationStatement';

interface P {
  base: BaseDto;
  user: User;
}

const resolveUpdatePaymentStatus = (reservation: SpaceReservation): SpaceReservationPaymentStatus => {
  if (!reservation || !reservation.paymentStatus) return SpaceReservationPaymentStatus.PAID;
  switch (reservation.paymentStatus) {
    case SpaceReservationPaymentStatus.PAID:
      return SpaceReservationPaymentStatus.UNPAID;
    case SpaceReservationPaymentStatus.UNPAID:
      return SpaceReservationPaymentStatus.PAID;
    default:
      return SpaceReservationPaymentStatus.PAID;
  }
};

const toReservationStatementProps = (reservation: SpaceReservation) => {
  // NOTE: 本来であれば ReservationSpace情報を使うが、仮眠室がうまく反映できていないための対応
  const { reservationType } = reservation.space!;
  return {
    spaceName: reservation.space?.spaceName ?? '',
    reservationType: reservationType,
    pricePerHour: reservation.pricePerHour,
    billingAmount: reservation.billingAmount,
    taxRate: reservation.taxRate,
    status: reservation.status,
    paymentMethod: reservation.paymentMethod,
    startAt: reservation.startAt,
    endAt: reservation.endAt,
    taxDiv: reservation.taxDiv
  };
};

const ReservationDetailScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { params, openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [reservation, setReservation] = useSafeState<SpaceReservation>(unmountRef);
  const [modalType, setModalType] = useSafeState<SpaceReservationStatus>(unmountRef, SpaceReservationStatus.CONFIRMED);
  const [isChangeSpaceModalOpen, setIsChangeSpaceModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalopen] = useSafeState<boolean>(unmountRef, false);
  const [isUpdating, setIsUpdating] = useSafeState<boolean>(unmountRef, false);
  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef);

  const isShowSpacePayment = useMemo<boolean>(() => {
    if (!contract) return true;
    return false;
  }, [contract]);

  const spaceReservationId = useMemo<SpaceReservationId>(() => params[SPACE_RESERVATION_ID], [params]);

  const isEnableToggle = useSafeCallback(
    (toggleCode: BaseFunctionToggleCode): boolean => {
      return Boolean(base.enabledFunctions?.map(e => e.toggleCode)?.includes(toggleCode));
    },
    [base]
  );

  const actionLabel = useMemo<Label>(() => {
    switch (modalType) {
      case SpaceReservationStatus.REJECTED:
        return '却下';
      case SpaceReservationStatus.CONFIRMED:
      default:
        return '確定';
    }
  }, [modalType]);

  const fetchSpaceReservation = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchSpaceReservationForAdminRequest>()
      .baseId(base.baseId)
      .spaceReservationId(spaceReservationId)
      .build();
    const response = await commonRequest<FetchSpaceReservationForAdminRequest, FetchSpaceReservationForAdminResponse>(
      FETCH_SPACE_RESERVATION_FOR_ADMIN,
      request
    );
    setReservation(response.spaceReservation);
    setContract(response.spaceReservation?.contract);
  }, [base.baseId, commonRequest, setContract, setReservation, spaceReservationId]);

  const handleSpaceReservationSaved = useSafeCallback(async (): Promise<void> => {
    setIsChangeSpaceModalOpen(false);
    await fetchSpaceReservation();
  }, [fetchSpaceReservation, setIsChangeSpaceModalOpen]);

  const updateSpaceReservationStatus = useSafeCallback(
    async (status: SpaceReservationStatus): Promise<void> => {
      setIsUpdating(true);
      const request = builder<UpdateSpaceReservationStatusForAdminRequest>()
        .baseId(base.baseId)
        .spaceReservationIds([spaceReservationId])
        .userId(user.userId)
        .status(status)
        .build();
      const response = await commonRequest<
        UpdateSpaceReservationStatusForAdminRequest,
        UpdateSpaceReservationStatusForAdminResponse
      >(UPDATE_SPACE_RESERVATION_STATUS_FOR_ADMIN, request);

      if (!response) return;

      await fetchSpaceReservation();
      setIsUpdating(false);
      setIsUpdateStatusModalopen(false);
    },
    [base, commonRequest, fetchSpaceReservation, setIsUpdateStatusModalopen, setIsUpdating, spaceReservationId, user]
  );

  const updateSpaceReservationPaymentStatus = useSafeCallback(async (): Promise<void> => {
    setIsUpdating(true);
    const request = builder<UpdateResourceReservationPaymentStatusForAdminRequest>()
      .baseId(base.baseId)
      .spaceReservationId(spaceReservationId)
      .paymentStatus(resolveUpdatePaymentStatus(reservation!))
      .build();
    const response = await commonRequest<
      UpdateResourceReservationPaymentStatusForAdminRequest,
      UpdateResourceReservationPaymentStatusForAdminResponse
    >(UPDATE_RESOURCE_RESERVATION_PAYMENT_STATUS_FOR_ADMIN, request);

    if (response.result === 'success') {
      openSnackbar('支払いステータスを更新しました', 'success', 4000);
      await fetchSpaceReservation();
    } else {
      openSnackbar('支払いステータスを更新に失敗しました', 'error', 4000);
    }
    setIsUpdating(false);
  }, [base, spaceReservationId, reservation]);

  // const openBillingDetailScreen = useSafeCallback((): void => {
  //   if (!reservation?.contract?.billings) return;
  //   const billingId = reservation.contract.billings[FIRST_INDEX].billingId;
  //   openPathInNewTab(embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, billingId]));
  // }, [base, openPathInNewTab, reservation]);

  useEffect(() => {
    fetchSpaceReservation();
  }, [fetchSpaceReservation]);

  const handleUpdateStatusModalOpen = useSafeCallback(
    (status: SpaceReservationStatus): void => {
      setModalType(status);
      setIsUpdateStatusModalopen(true);
    },
    [setIsUpdateStatusModalopen, setModalType]
  );

  const headerRightButtonComponent = useMemo<React.ReactNode>(() => {
    if (!reservation) return <></>;
    const rejectButton = (
      <ButtonV2
        key={SpaceReservationStatus.REJECTED}
        type='tertiary'
        label='却下'
        onClick={() => handleUpdateStatusModalOpen(SpaceReservationStatus.REJECTED)}
      />
    );
    const confirmButton = (
      <ButtonV2
        key={SpaceReservationStatus.CONFIRMED}
        startIcon={<CheckRoundedIcon />}
        type='primary'
        label='確定'
        onClick={() => handleUpdateStatusModalOpen(SpaceReservationStatus.CONFIRMED)}
      />
    );
    switch (reservation.status) {
      case SpaceReservationStatus.PROVISIONAL:
        return [rejectButton, confirmButton];
      case SpaceReservationStatus.CONFIRMED:
        return rejectButton;
      default:
        return <></>;
    }
  }, [handleUpdateStatusModalOpen, reservation]);

  return (
    <Screen loading={!reservation} loadingType='circular' className='reservation-detail-screen'>
      {reservation && (
        <Container>
          <BackButtonV2 label='戻る' onClick={() => openBasePath(Path.RESERVATION_LIST)} />
          <PageHeaderV2
            title={reservation.space?.spaceName ? `${reservation.space?.spaceName}の予約` : '予約'}
            rightComponent={<ButtonWrapper>{headerRightButtonComponent}</ButtonWrapper>}
          />
          <Content>
            <Card title='基本情報'>
              <ReservationDetailBasic
                reservation={reservation}
                isEnableEditEquipment={isEnableToggle(
                  BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_CONTROL_EQUIPMENT_EDIT
                )}
                isShowSpacePayment={isShowSpacePayment}
                baseCode={base.baseCode}
                setModalOpen={setIsChangeSpaceModalOpen}
              />
            </Card>
            <Card title='その他の情報'>
              <ReservationDetailOther
                reservation={reservation}
                isShowSpacePayment={isShowSpacePayment}
                isFeatureReservationSpacePayment={isEnableToggle(
                  BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT
                )}
                onClickUpdatePaymentStatus={updateSpaceReservationPaymentStatus}
              />
            </Card>

            <Card title='利用明細'>
              <ReservationStatement
                reservation={toReservationStatementProps(reservation)}
                discountRate={reservation.discountRate}
                isContractUser={Boolean(reservation.contract)}
                isContractPlanDiscountRateTarget={
                  reservation.space?.baseResourceCategory?.isContractPlanDiscountRateTarget
                }
                isContractPlanFreeTierTarget={reservation.space?.baseResourceCategory?.isContractPlanFreeTierTarget}
              />
            </Card>
          </Content>

          <ChangeSpaceModal
            baseId={base.baseId}
            userId={user.userId}
            reservation={reservation}
            baseResourceCategories={base.baseResourceCategories ?? []}
            isOpen={isChangeSpaceModalOpen}
            onClose={() => setIsChangeSpaceModalOpen(false)}
            onSave={handleSpaceReservationSaved}
          />
          <DefaultModal
            isOpen={isUpdateStatusModalOpen}
            headerLabel={`予約を${actionLabel}`}
            rightButtonProps={{
              disabled: isUpdating,
              label: '確定',
              onClick: () => updateSpaceReservationStatus(modalType)
            }}
            onClose={() => setIsUpdateStatusModalopen(false)}
          >
            予約を{actionLabel}します。
          </DefaultModal>
          <ScreenLoaderV2 loading={isUpdating} />
        </Container>
      )}
    </Screen>
  );
});

ReservationDetailScreen.displayName = 'ReservationDetailScreen';
export default ReservationDetailScreen;

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  margin: 0 auto;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
