import {
  ButtonV2,
  CardV2,
  CardWithLabelV2,
  Component,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  CHECK_SPACE_RESERVATION_LIMIT,
  CheckSpaceReservationLimitRequest,
  CheckSpaceReservationLimitResponse,
  ContractV2,
  ContractVersion,
  GET_CONTRACT_USAGE_OVERVIEW,
  GetContractUsageOverviewRequest,
  GetContractUsageOverviewResponse,
  GetContractUsageOverviewSuccessResponse,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Count, Hour, Label, MilliSecond, Text, Title } from '@atomica.co/types';
import {
  EMPTY,
  Language,
  MILLI_SECONDS_OF_ONE_SECOND,
  SECONDS_OF_ONE_HOUR,
  builder,
  hasLength,
  isGreaterThanZero,
  isNull,
  isUndefined
} from '@atomica.co/utils';
import { Divider } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ContractUsageV2Dto } from '../../../../__generated/model';
import { USABLE_HOURS_OF_PLAN_V1, toContractPlanLabel } from '../../../../constants/base-const';
import usePath from '../../../../redux/hooks/usePath';
import CommonRequest from '../../../../requests/common-request';
import { Path } from '../../../../router/Routes';
import { getContractVersion } from '../../../../utils/contract-v2-util';
import { toTimeStr } from '../../../../utils/date-util';

interface P {
  base: BaseDto;
  user: User;
  contract: ContractV2 | undefined;
  contractUsage: ContractUsageV2Dto | undefined;
}

const formatUsage = (usage: MilliSecond, limit: Hour | undefined): Text => {
  const jp = Language.JAPANESE;
  return limit
    ? `${toTimeStr(usage, jp)} / ${toTimeStr(limit * SECONDS_OF_ONE_HOUR * MILLI_SECONDS_OF_ONE_SECOND, jp)}`
    : toTimeStr(usage, jp);
};

const formatRemaining = (usage: MilliSecond, limit: Hour | undefined): Text => {
  const jp = Language.JAPANESE;
  if (!limit) return toTimeStr(usage, jp);
  const remining = limit * SECONDS_OF_ONE_HOUR * MILLI_SECONDS_OF_ONE_SECOND - usage;
  return toTimeStr(isGreaterThanZero(remining) ? remining : 0, jp);
};

const Card: React.FC<{ title: Title; children: React.ReactNode }> = ({ title, children }) => {
  return (
    <CardContent>
      <TitleWrapper>
        <TitleText>{title}</TitleText>
        <StyledDivider />
      </TitleWrapper>
      {children}
    </CardContent>
  );
};

const AccountUsageSituationCard: React.FC<P> = memo(props => {
  const { base, user, contract, contractUsage } = props;
  const { openBasePath } = usePath();

  const unmountRef = useUnmountRef();
  const [contractOverview, setContractOverview] =
    useSafeState<Omit<GetContractUsageOverviewSuccessResponse, 'result'>>(unmountRef);
  const [spaceUsageOfNonContractUser, setSpaceUsageOfNonContractUser] = useSafeState<MilliSecond>(unmountRef, -1);
  const [loading, setLoading] = useSafeState<boolean>(unmountRef, false);

  const [spaceUsageMilliSeconds, spaceRemainUsageMilliSeconds, spaceReservationLimit] = useMemo<
    [MilliSecond, MilliSecond, Hour | undefined]
  >(() => {
    return [
      contractOverview?.reservationUsage?.usageMilliSeconds ?? 0,
      contractOverview?.reservationUsage?.remainMilliSeconds ?? 0,
      contractOverview?.contractPlan?.reservationLimitHoursPerMonthByContract ??
        base.reservationLimitHoursPerMonthByUser
    ];
  }, [base, contractOverview]);

  const contractVersion = useMemo<ContractVersion | undefined>(
    () => getContractVersion(user, contract),
    [user, contract]
  );

  const contractPlanLabel = useMemo<Label>(() => {
    switch (contractVersion) {
      case ContractVersion.V1:
        return toContractPlanLabel(base.baseCode, user.contract?.contractPlan);
      case ContractVersion.V2:
        return contractOverview?.contractPlan?.planName || EMPTY;
      default:
        return EMPTY;
    }
  }, [base, contractVersion, user, contractOverview]);

  const usableHours = useMemo<Hour | null>(() => {
    switch (contractVersion) {
      case ContractVersion.V1:
        return USABLE_HOURS_OF_PLAN_V1[contractPlanLabel];
      case ContractVersion.V2:
        return contractOverview?.contractPlan?.usableHoursForFreeSpaces ?? null;
      default:
        return null;
    }
  }, [contractPlanLabel, contractVersion, contractOverview]);

  // const isReservationLimited = useMemo<boolean>(() => {
  //   if (isNull(spaceReservationLimit) || isUndefined(spaceReservationLimit)) return false;
  //   return spaceUsageMilliSeconds >= spaceReservationLimit * SECONDS_OF_ONE_HOUR * MILLI_SECONDS_OF_ONE_SECOND;
  // }, [spaceUsageMilliSeconds, spaceReservationLimit]);

  const availablePoints = useMemo((): Count => {
    const now = new Date();
    return hasLength(user.userPoints)
      ? user.userPoints
          .filter(point => !point.expirationDate || point.expirationDate >= now)
          .reduce((sum, point) => (sum += point.point), 0)
      : 0;
  }, [user]);

  const openAccountPointUsageLogScreen = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_POINT_USAGE_LOGS);
  }, [openBasePath]);

  const getContractUsageOverview = useSafeCallback(async (): Promise<void> => {
    setLoading(true);
    if (!user) return;
    if (contractVersion === ContractVersion.V1 || contractVersion === ContractVersion.V2) {
      const request = builder<GetContractUsageOverviewRequest>().baseId(base.baseId).userId(user.userId).build();
      const response = await CommonRequest.call<GetContractUsageOverviewRequest, GetContractUsageOverviewResponse>(
        GET_CONTRACT_USAGE_OVERVIEW,
        request
      );
      if (response.result && response.contractId) {
        setContractOverview(response);
      }
    } else {
      const request = builder<CheckSpaceReservationLimitRequest>().baseId(base.baseId).userId(user.userId).build();
      const response = await CommonRequest.call<CheckSpaceReservationLimitRequest, CheckSpaceReservationLimitResponse>(
        CHECK_SPACE_RESERVATION_LIMIT,
        request
      );
      setSpaceUsageOfNonContractUser(response.usageHours * SECONDS_OF_ONE_HOUR * MILLI_SECONDS_OF_ONE_SECOND);
    }
    setLoading(false);
  }, [base, user, setContractOverview]);

  useEffect(() => {
    getContractUsageOverview();
  }, [getContractUsageOverview]);

  return (
    <Component style={{ width: '100%', height: '100%' }} loading={loading} className='account-usage-situation-card'>
      <Container>
        {!isUndefined(contractVersion) && (
          <Card title='ご契約プラン'>
            <CardWithLabelV2
              label={contractPlanLabel}
              text={isNull(usableHours) ? '無料' : `${usableHours}時間まで無料`}
            />
          </Card>
        )}
        <Card title='会議室等ご利用状況'>
          {contractVersion === ContractVersion.V1 || contractVersion === ContractVersion.V2 ? (
            <CardWithLabelV2
              label={`今月のご利用時間 ${spaceReservationLimit ? '/ 上限時間' : EMPTY}`}
              text={formatUsage(spaceUsageMilliSeconds, spaceReservationLimit)}
            />
          ) : (
            <CardWithLabelV2
              label={`今月のご利用時間 ${spaceReservationLimit ? '/ 上限時間' : EMPTY}`}
              text={formatUsage(spaceUsageOfNonContractUser, spaceReservationLimit)}
            />
          )}
          {contractVersion === ContractVersion.V2 && contract?.contractPlan?.freeTier && (
            <CardWithLabelV2
              label='今月の無料残り時間'
              text={formatRemaining(spaceRemainUsageMilliSeconds, undefined)}
            />
          )}
          {/* NOTE: こちらのエラーメッセージに関しては、無制限に予約できるスペースと上限が設定されているスペースが計算ロジックに含まれているため、 分離できるまでは出さない */}
          {/* {isReservationLimited && <ErrorMessage>今月の予約上限を超えているため、予約できません。</ErrorMessage>} */}
        </Card>
        {!isUndefined(contractVersion) && (
          <Card title='フリースペースご利用状況'>
            <CardWithLabelV2
              label={`今月のご利用時間 ${!isNull(usableHours) ? '/ 上限時間' : EMPTY}`}
              text={formatUsage(contractUsage?.usageMsec ?? 0, usableHours ?? 0)}
            />
            {!isNull(usableHours) && (
              <CardWithLabelV2
                label='今月の残り時間'
                text={formatRemaining(contractUsage?.usageMsec ?? 0, usableHours ?? 0)}
              />
            )}
          </Card>
        )}
        {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT) && (
          <Card title='利用可能ポイント'>
            <CardV2
              text={`${availablePoints} ポイント`}
              component={
                <CustomLinkButton
                  size='small'
                  label='利用履歴'
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={openAccountPointUsageLogScreen}
                />
              }
            />
          </Card>
        )}
      </Container>
    </Component>
  );
});

AccountUsageSituationCard.displayName = 'AccountUsageSituationCard';
export default AccountUsageSituationCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const TitleText = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const StyledDivider = styled(Divider)`
  background: ${themeV2.mixins.v2.color.border.gray};
`;

// const ErrorMessage = styled.div`
//   ${themeV2.mixins.v2.typography.body.large};
//   color: ${themeV3.mixins.v3.color.object.error};
// `;

const CustomLinkButton = styled(ButtonV2)`
  && {
    ${themeV2.mixins.v2.typography.body.large};
    color: ${themeV2.mixins.v2.color.font.pink};
  }
`;
