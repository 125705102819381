import { InputType, Status } from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BaseResourceCategoryId,
  BaseResourceCategoryOptionValidationType,
  CancelFeeSpanType,
  CancelFeeType,
  CancelPolicy,
  ContractV2,
  ManuallyChangedSpaceReservationStatus,
  PaymentMethod,
  Space,
  SpacePaymentFrequency,
  SpaceReservation,
  SpaceReservationPaymentStatus,
  SpaceReservationStatus,
  SpaceReservationType,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Day, Description, Hour, Label, Minute } from '@atomica.co/types';
import {
  BREAK,
  EMPTY,
  MINUS_ONE,
  ONE,
  ZERO,
  calcTimeDiff,
  hasLength,
  toBeginningOfDay,
  toHour
} from '@atomica.co/utils';
import { addDays, addMinutes, differenceInDays, format, isBefore } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';
import { DEFAULT_START_UNIT } from '../constants/space-const';
import { SPACE_RESERVATION_STATUS_LABEL } from '../texts/space-text';
import { isNotFoundIndex } from './common-util';
import { isValidContractUser } from './contract-user-v2-util';
import { isValidContract } from './contract-v2-util';
import { getBasePICV2 } from './user-util';

export const reservationTimeToStr = (day?: Date, startAt?: Date, endAt?: Date): string => {
  if (!day || !startAt || !endAt) return EMPTY;
  return `${format(day, 'M月d日 (E) ', { locale: jaLocale })} ${format(startAt, 'HH:mm', {
    locale: jaLocale
  })} - ${format(endAt, 'HH:mm', { locale: jaLocale })}`;
};

export const dueTimeToStr = (date?: Date): string => {
  if (!date) return EMPTY;
  return `${format(date, 'yyyy年M月d日 (E) HH:mm', { locale: jaLocale })} 迄`;
};

export const filterResourcesByUser = (base: BaseDto, spaces: Space[], user?: User): Space[] => {
  const pics = user ? getBasePICV2(user, base) : [];
  const resourceAuthorityIds = pics.map(pic => pic.authority?.authorityId);
  return spaces.filter(
    space =>
      !hasLength(space.authorities) ||
      space.authorities!.some(spaceAuthority => resourceAuthorityIds.includes(spaceAuthority.authority?.authorityId))
  );
};

export const filterResourcesByCategory = (spaces: Space[], categoryId?: BaseResourceCategoryId): Space[] => {
  return categoryId
    ? spaces.filter(space => space.baseResourceCategory?.baseResourceCategoryId === categoryId)
    : spaces.filter(space => !space.baseResourceCategory?.isLimitedContract);
};

export const isReceptionOpened = (space: Space, date: Date): boolean => {
  const today = toBeginningOfDay(new Date())!;
  const { reservationStartDayOffset } = space;
  const reservationStartDate = isNotFoundIndex(reservationStartDayOffset)
    ? today
    : addDays(today, reservationStartDayOffset);
  return !isBefore(date, reservationStartDate);
};

export const isReceptionClosed = (space: Space, date: Date): boolean => {
  const today = toBeginningOfDay(new Date())!;
  const { reservationAdvanceBookingDays } = space;
  if (isNotFoundIndex(reservationAdvanceBookingDays)) return false;
  const reservationEndDate = addDays(today, reservationAdvanceBookingDays + 1);
  return reservationEndDate < date;
};

export const isReceptionClosedNotAddOneDay = (space: Space, date: Date): boolean => {
  const today = toBeginningOfDay(new Date())!;
  const { reservationAdvanceBookingDays } = space;
  if (isNotFoundIndex(reservationAdvanceBookingDays)) return false;
  const reservationEndDate = addDays(today, reservationAdvanceBookingDays);
  return reservationEndDate < date;
};

export const getReservationStartUnit = (spaces: Space[]): Minute => {
  if (!hasLength(spaces)) return DEFAULT_START_UNIT;
  const space = spaces.sort((a, b) => a.reservationStartUnit - b.reservationStartUnit)[ZERO];
  return space.reservationStartUnit ? space.reservationStartUnit : DEFAULT_START_UNIT;
};

export const isReservationTimeFinished = (reservation: SpaceReservation): boolean => {
  if (!reservation) return true;
  const space = reservation.space;
  const bufferMinutes = space?.bufferMinutes || 0;
  return !!reservation.checkoutAt || !isBefore(new Date(), addMinutes(reservation.endAt!, bufferMinutes));
};

export const toStatusFromSpaceReservation = (reservation: SpaceReservation): Status => {
  if (isReservationTimeFinished(reservation)) return 'default';

  switch (reservation.status) {
    case SpaceReservationStatus.CONFIRMED:
      return 'success';
    case SpaceReservationStatus.PROVISIONAL:
      return 'warning';
    case SpaceReservationStatus.CANCELED:
    case SpaceReservationStatus.REJECTED:
      return 'default';
    default:
      return 'default';
  }
};

export const toStatusLabelFromSpaceReservation = (reservation: SpaceReservation): Label => {
  if (
    reservation.manuallyChangedStatus &&
    reservation.manuallyChangedStatus === ManuallyChangedSpaceReservationStatus.CANCELED &&
    reservation.status === SpaceReservationStatus.REMOVED_GOOGLE_CALENDAR
  )
    return SPACE_RESERVATION_STATUS_LABEL[SpaceReservationStatus.CANCELED];
  if (
    reservation.manuallyChangedStatus &&
    reservation.manuallyChangedStatus === ManuallyChangedSpaceReservationStatus.REJECTED &&
    reservation.status === SpaceReservationStatus.REMOVED_GOOGLE_CALENDAR
  )
    return SPACE_RESERVATION_STATUS_LABEL[SpaceReservationStatus.REJECTED];
  return SPACE_RESERVATION_STATUS_LABEL[reservation.status];
};

export const toPaymentStatusFromSpaceReservation = (reservation: SpaceReservation): Status => {
  if (isReservationTimeFinished(reservation)) return 'default';

  switch (reservation.paymentStatus) {
    case SpaceReservationPaymentStatus.PAID:
      return 'success';
    case SpaceReservationPaymentStatus.UNPAID:
      if (reservation.startAt! < new Date()) {
        return 'error';
      }
      return 'default';
    default:
      return 'default';
  }
};

export const getSpaceReservationStatusLabel = (reservation: SpaceReservation): Label => {
  if (isReservationTimeFinished(reservation)) return '利用終了';
  const { status } = reservation;
  return SPACE_RESERVATION_STATUS_LABEL[status];
};

const buildCancelFeeSpan = (policy: CancelPolicy): Description => {
  switch (policy.cancelFeeSpanType) {
    case CancelFeeSpanType.DAY:
      if (policy.cancelFeeSpanValue === MINUS_ONE) {
        return '予約確定後から';
      }
      if (policy.cancelFeeSpanValue > ZERO) {
        return `ご利用日時の${policy.cancelFeeSpanValue}日前から`;
      }
      return 'ご利用日時の当日';
    case CancelFeeSpanType.HOUR:
      if (policy.cancelFeeSpanValue === MINUS_ONE) {
        return '予約確定後から';
      }
      if (policy.cancelFeeSpanValue > ZERO) {
        return `ご利用日時の${policy.cancelFeeSpanValue}時間前から`;
      }
      return '開始時間以降';
    default:
      return EMPTY;
  }
};

export const buildCancelPolicyDescription = (policies: CancelPolicy[]): Description => {
  return policies
    .sort((a, b) => {
      if (a.cancelFeeSpanValue === MINUS_ONE) return MINUS_ONE;
      if (b.cancelFeeSpanValue === MINUS_ONE) return ONE;

      if (a.cancelFeeSpanValue === ZERO) return ONE;
      if (b.cancelFeeSpanValue === ZERO) return MINUS_ONE;

      return b.cancelFeeSpanValue - a.cancelFeeSpanValue;
    })
    .map(cp => {
      const cancelFeeSpan = buildCancelFeeSpan(cp);
      const cancelFeeValue = `${cp.cancelFeeValue}${cp.cancelFeeType === CancelFeeType.AMOUNT ? '円' : '%'}`;
      return `${cancelFeeSpan}：${cancelFeeValue}`;
    })
    .join(BREAK);
};

export const toInputTypeFromBaseResourceCategoryOptionValidationType = (
  validationType: BaseResourceCategoryOptionValidationType
): InputType => {
  switch (validationType) {
    case BaseResourceCategoryOptionValidationType.ALPHANUMERIC:
      return 'alphanumeric';
    case BaseResourceCategoryOptionValidationType.NUMBER:
      return 'number';
    default:
      return 'text';
  }
};

export const calcUsagePeriod = (reservationType: SpaceReservationType, startAt: Date, endAt: Date): Hour | Day => {
  switch (reservationType) {
    case SpaceReservationType.TIME:
      return toHour(calcTimeDiff(startAt, endAt));
    case SpaceReservationType.DATE:
      return differenceInDays(endAt.setHours(ZERO), startAt.setHours(ZERO));
    default:
      throw new Error(`${reservationType} is out of target.`);
  }
};

export const useCreditCardPayment = (
  base: BaseDto,
  contract?: ContractV2,
  baseDate?: Date | null,
  targetUser?: User
): boolean => {
  if (!contract || !isValidContract(contract, baseDate) || !isValidContractUser(contract, targetUser)) return true;

  switch (contract.paymentMethod) {
    case PaymentMethod.CREDIT_CARD:
      if (!isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT))
        return false;
      switch (contract.spacePaymentFrequency) {
        case SpacePaymentFrequency.PAY_PER_USE:
          return true;
        case SpacePaymentFrequency.MONTHLY_PAYMENT:
        default:
          return false;
      }
    case PaymentMethod.BANK_TRANSFER:
    case PaymentMethod.CASH:
    case PaymentMethod.INVOICE:
    default:
      return false;
  }
};
